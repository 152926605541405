import React from "react";

const PrivacyPolicy = () => {
  return (
    <>
      <div className="rainbow-service-area rainbow-section-gap">
        <div className="container">
          <div className="rbt-daynamic-page-content center-width">
            <div className="banner-area">
              <div className="settings-area">
                <h3 className="title">Privacy Policy</h3>
              </div>
            </div>
            <div className="content-page ">
              <div className="chat-box-list">
                <div className="content">
                  <p>
                    UPCX-Platforms Pte. Ltd. (hereinafter referred to as "UPCX")
                    recognizes that personal data is an important asset of
                    individuals. UPCX will appropriately acquire and use
                    personal data including but not limited to names, addresses,
                    telephone numbers, and e-mail addresses of customers that
                    access and use this website (hereinafter referred to as
                    “customers”). UPCX will comply with laws and regulations
                    concerning personal data, and prevent and correct leaks of
                    personal data. Therefore, UPCX has established the following
                    policy for handling personal data and will manage and
                    operate it with the utmost care. Please read this Privacy
                    Policy before using this website. By agreeing to this
                    Privacy Policy, or by providing us with information needed
                    to use our website or registering to use our services, you
                    are deemed to give your consent and agree to our use,
                    acquisition, sharing, and otherwise processing of your
                    personal data in accordance with this Privacy Policy.
                  </p>
                </div>
              </div>
            </div>
            <div className="content-page pb--50">
              <div className="chat-box-list">
                <div className="content">
                  <h4>1.Scope of Application</h4>
                  <p>
                    This Privacy Policy is prepared in accordance with the
                    Personal Data Protection Act of 2012 ("PDPA") of Singapore.
                    This Privacy Policy applies to any of our services that are
                    used by you. From time to time, we may outsource the
                    operation of some of our services to outside contractors.
                    This Privacy Policy also applies to personal data obtained
                    by outside contractors in connection with providing these
                    services. However, this Privacy Policy does not apply to
                    personal data acquired by outside contractors for purposes
                    unrelated to UPCX.
                  </p>
                  <h4>2. Acquisition of Personal Data</h4>
                  <p>
                    The information we collect from our customers includes the
                    following information provided by customers when using our
                    services:
                  </p>
                  <ol className="ml--20">
                    <li>Name;</li>
                    <li>Address;</li>
                    <li>Date of birth;</li>
                    <li>Phone number;</li>
                    <li>
                      Account information (username, password, and other
                      authentication information used to access our services);
                    </li>
                    <span>and</span>
                    <li>
                      E-mail address. If you refuse to provide any of the above
                      information that is required to use our services, or any
                      other information we request for applicable legal and
                      compliance checks, you will not be able to use our
                      services.
                    </li>
                  </ol>
                  <h4>3. Purpose of Collection and Use of Personal Data</h4>
                  <p>
                    We will use your personal data for the following purposes:
                  </p>
                  <ol className="ml--20">
                    <li>
                      Operation and management of our website and services, such
                      as login information to access member-only pages,
                      authentication after login, automatic displays of
                      information on various screens, etc.;
                    </li>
                    <li>
                      Notification of maintenance information, important
                      information, and other matters necessary for operating our
                      website and services;
                    </li>
                    <li>
                      Protection and assurance of the technical functionality,
                      safety, and integrity of our website and services;
                    </li>
                    <li>
                      Response to inquiries about our website and services;
                    </li>
                    <li>
                      Other purposes necessary for providing our website and
                      services;
                    </li>
                    <li>Marketing and promotional activities of UPCX;</li>
                    <li>
                      Surveys and analysis for improving our website and
                      services;
                    </li>
                    <li>Accounting, risk management, and record management;</li>
                    <li>Compliance with laws and regulations;</li>
                    <li>
                      Prevention, detection, investigation, or monitoring of
                      fraud, threats, illegal activities, or other prohibited
                      content, operations, or activities; and/or
                    </li>
                    <li>
                      Handling and resolution of disputes arising from or
                      related to our website and services.
                    </li>
                  </ol>
                  <h4>4.Disclosure of Personal Data to Third Parties</h4>
                  <p>
                    For the purposes listed above, we may disclose or provide
                    customers' personal data to third parties in the following
                    cases:
                  </p>
                  <ol className="ml--20">
                    <li>When your consent has been obtained for disclosure;</li>
                    <li>
                      When disclosure is requested by legal institutions such as
                      police agencies or courts, or when disclosure is required
                      by law; or
                    </li>
                    <li>
                      When disclosing to professionals who provide services to
                      UPCX (including but not limited to compliance checks,
                      service evaluations, data storage, maintenance, legal
                      services, auditing, insurance, and marketing services).
                    </li>
                  </ol>
                  <h4>5.Cross-border Transfer of Personal Data</h4>
                  <p>
                    Your personal data may be transferred to other countries for
                    processing. By using our website and services, you consent
                    to the potential transfer, use, and processing of your
                    personal data to places outside of Singapore, including but
                    not limited to Japan, which may have data protection laws
                    different from those of Singapore. However, the level of
                    protection applicable to personal data transferred outside
                    of Singapore shall be the same as that set forth in this
                    Privacy Policy and the PDPA of Singapore.
                  </p>
                  <h4>6. Management of Personal Data</h4>
                  <p>
                    We will not store your personal data beyond the purposes for
                    which it was collected. In handling personal data, we will,
                    in accordance with the current technical standards,
                    continuously take all necessary and appropriate technical
                    and organizational security control measures, to prevent
                    among others unauthorized access, unauthorized disclosure,
                    loss, destruction, and falsification. However, data
                    transmitted over the Internet cannot be completely
                    guaranteed to be secure during transmission. Therefore, we
                    cannot guarantee the security of any information you
                    transmit to us or that we transmit to you prior to receipt.
                    Any transmission is done at your own risk.
                  </p>
                  <h4>7. Rights to Personal Data</h4>
                  <p>
                    You may request information regarding access to your
                    personal data obtained by UPCX in connection to your use of
                    our website and services, as well as the manner in which
                    UPCX has used and disclosed your personal data within the
                    past one year prior to receiving your request. You may also
                    request to review, correct, or delete your personal data.
                    However, we reserve the right to charge fees for the cost of
                    responding to such a request. You may withdraw your consent
                    to use your personal data at any time in any manner or for
                    any specific purpose. However, please note that if you
                    withdraw your consent, there may be consequences, including
                    the unavailability of our website and/or services. Please
                    also note that despite your withdrawal of consent, we
                    reserve the right to retain your personal data for as long
                    as necessary for our legal or business purposes. To request
                    access to your personal data, correct your personal data, or
                    to withdraw your consent to our use of your personal data,
                    please contact the Data Protection Officer ("DPO") in
                    accordance with Section 9 (Inquiries) below. To protect your
                    privacy and security, we will take reasonable steps to
                    verify your identity before processing your request.
                  </p>
                  <h4>
                    8. Changes, Updates, and Deletions to this Privacy Policy
                  </h4>
                  <p>
                    We reserve the right to update, amend, delete, or otherwise
                    change this Privacy Policy at any time. Any changes to this
                    Privacy Policy will be posted on this website, emailed to
                    you, or otherwise reasonably communicated to you. Any
                    changes to this Privacy Policy will be effective and binding
                    upon the date of such a notice. Your continued use of our
                    website or services constitutes your acknowledgement and
                    acceptance of such changes.
                  </p>
                  <h4>9. Inquiries</h4>
                  <p>
                    Requests for disclosure of personal data, opinions,
                    questions, complaints, or any other inquiries regarding our
                    handling of personal data should be made to the following
                    address: Address: 18 ROBINSON ROAD #20- 02 18 ROBINSON
                    SINGAPORE (048547) E-mail: tech-admin@upcx.org
                  </p>
                  <h4>10. Compliance with Laws and Regulations</h4>
                  <p>
                    Unless otherwise set out above, UPCX shall handle any other
                    necessary matters regarding the handling of personal data in
                    accordance with the PDPA.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default PrivacyPolicy;
