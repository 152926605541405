import { Outlet, Route, Routes } from "react-router-dom";
import { UPC } from "./component/Token/UPC";
import { MarketTrends } from "./component/VisionTeam/MarketTrends";
import { WrappedLayout } from "./layout/WrappedLayout";
import { CommingSoon } from "./page/CommingSoon";
import Connect from "./page/Connect";
import ContactUs from "./page/ContactUs";
import CookiePolicy from "./page/CookiePolicy";
import { Ecosystem } from "./page/Ecosystem";
import { Functions } from "./page/Functions";
import { Home } from "./page/Home";
import LegalDisclaimer from "./page/LegalDisclaimer";
import { NotFound } from "./page/NotFound";
import PrivacyPolicy from "./page/PrivacyPolicy";
import { Products } from "./page/Products";
import { SmartContract } from "./page/SmartContract";
import { Solution } from "./page/Solution";
import { SuperApplication } from "./page/SuperApplication";
import { Technology } from "./page/Technology";
import { Token } from "./page/Token";
import { VisionTeam } from "./page/VisionTeam";

export function Body() {
  return (
    <Routes>
      <Route
        element={
          <WrappedLayout>
            <Outlet />
          </WrappedLayout>
        }
      >
        <Route path="/" element={<Home />} />
        <Route path="/solution" element={<Solution />} />
        <Route path="/ecosystem" element={<Ecosystem />} />
        <Route path="/token" element={<Token />} />
        <Route path="/super-application" element={<SuperApplication />} />
        <Route path="/technology" element={<Technology />} />
        <Route path="/smartcontract" element={<SmartContract />} />
        <Route path="/functions" element={<Functions />} />
        <Route path="/products" element={<Products />} />
        <Route path="/vision-team" element={<VisionTeam />} />
        <Route path="/privacy-policy" element={<PrivacyPolicy />} />
        <Route path="/legal-disclaimer" element={<LegalDisclaimer />} />
        <Route path="/cookie-policy" element={<CookiePolicy />} />
        <Route path="/contact_us" element={<ContactUs />} />
        <Route path="/connect" element={<Connect />} />
        <Route path="/*" element={<NotFound />} />
      </Route>
    </Routes>
  );
}
