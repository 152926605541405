export const NotFound = () => {
  return (
    <>
      <div className="container">
        <div className="row justify-content-center">
          <div className="col-lg-12">
            <div
              className="inner text-center "
              style={{
                height: "calc(100vh - 716px)",
                display: "flex",
                minHeight: "250px",
              }}
            >
              <h2 className="title display-one" style={{ margin: "auto" }}>
                <span
                  className="theme-gradient mt--30"
                  style={{ fontSize: "100px" }}
                >
                  Not Found
                </span>
              </h2>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
