import { Link } from "react-router-dom";
import { Separator } from "../component/Separator";
import LOGO_DARK from "../asset/images/logo/upcx_logo_dark.png";
import LOGO_LIGHT from "../asset/images/logo/upcx_logo_white.png";
import FOOTER_DATA from "../data/footer.json";
import SingleFooter from "../component/SingleFooter";
import XIcon from "@mui/icons-material/X";
import EmailIcon from "@mui/icons-material/Email";
import { FaGithub, FaDiscord, FaTelegram, FaMedium } from "react-icons/fa";

export const Footer = () => {
  return (
    <>
      <div className="rainbow-footer footer-style-default footer-style-3 position-relative mt--50">
        <Separator top={true} />
        <div className="footer-top pt--150">
          <div className="container">
            {FOOTER_DATA &&
              FOOTER_DATA.footer.map((data, index) => (
                <div className="row" key={index}>
                  <div className="col-xl-3 col-lg-4 col-md-6 col-sm-12 col-12">
                    <div className="logo">
                      <Link to="/">
                        <img
                          className="logo-light"
                          src={LOGO_LIGHT}
                          alt="Corporate Logo"
                        />
                        <img
                          className="logo-dark"
                          src={LOGO_DARK}
                          alt="Corporate Logo"
                        />
                      </Link>
                    </div>
                    <div className="rainbow-footer-widget d-flex mb-5">
                      <Link
                        className="footer-sns"
                        to="mailto:tech-admin@upcx.org"
                        rel="nofollow noopener"
                        target="_blank"
                        data-bs-toggle="tooltip"
                        data-bs-trigger="hover"
                        data-bs-placement="top"
                        aria-label="Twitter"
                      >
                        <EmailIcon
                          sx={{
                            width: 30,
                            height: 30,
                          }}
                        />
                      </Link>

                      <Link
                        className="footer-sns"
                        to="https://medium.com/@UPCX-Platforms"
                        rel="nofollow noopener"
                        target="_blank"
                        data-bs-toggle="tooltip"
                        data-bs-trigger="hover"
                        data-bs-placement="top"
                        aria-label="Twitter"
                      >
                        <FaMedium
                          size={30}
                          style={{ color: "white !important" }}
                        />
                      </Link>

                      <Link
                        className="footer-sns"
                        to="https://twitter.com/Upcxofficial"
                        rel="nofollow noopener"
                        target="_blank"
                        data-bs-toggle="tooltip"
                        data-bs-trigger="hover"
                        data-bs-placement="top"
                        aria-label="Twitter"
                      >
                        <XIcon
                          sx={{
                            width: 30,
                            height: 30,
                          }}
                        />
                      </Link>

                      <Link
                        className="footer-sns"
                        to="https://t.me/UPCXofficial"
                        rel="nofollow noopener"
                        target="_blank"
                        data-bs-toggle="tooltip"
                        data-bs-trigger="hover"
                        data-bs-placement="top"
                        aria-label="Twitter"
                      >
                        <FaTelegram
                          size={30}
                          style={{ color: "white !important" }}
                        />
                      </Link>
                      <Link
                        className="footer-sns"
                        to="http://dsc.gg/upcx"
                        rel="nofollow noopener"
                        target="_blank"
                        data-bs-toggle="tooltip"
                        data-bs-trigger="hover"
                        data-bs-placement="top"
                        aria-label="Twitter"
                      >
                        <FaDiscord
                          size={30}
                          style={{ color: "white !important" }}
                        />
                      </Link>
                      <Link
                        className="footer-sns"
                        to="https://github.com/UPCX-TEAM-B"
                        rel="nofollow noopener"
                        target="_blank"
                        data-bs-toggle="tooltip"
                        data-bs-trigger="hover"
                        data-bs-placement="top"
                        aria-label="Twitter"
                      >
                        <FaGithub
                          size={30}
                          style={{ color: "white !important" }}
                        />
                      </Link>
                    </div>
                  </div>
                  {/* <SingleFooter data={data.services} /> */}
                  {/* <SingleFooter data={data.products} /> */}
                  <div className="col-lg-3 col-md-6 col-sm-12 col-12" />
                  <SingleFooter data={data.company} />
                  <SingleFooter data={data.community} />
                  {/* <SingleFooter data={data.solutions} /> */}
                </div>
              ))}
          </div>
        </div>
      </div>
      <div className="copyright-area copyright-style-one">
        <div className="container">
          <div className="row align-items-center">
            {/* <div className="col-lg-6 col-md-8 col-sm-12 col-12">
              <div className="copyright-left">
                <ul className="ft-menu link-hover">
                  <li>
                    <Link to="/privacy-policy">Privacy Policy</Link>
                  </li>
                  <li>
                    <Link to="/terms-policy">Terms And Condition</Link>
                  </li>
                  <li>
                    <Link to="/contact">Contact Us</Link>
                  </li>
                </ul>
              </div>
            </div> */}
            <div className="col-lg-12 col-md-12 col-sm-12 col-12">
              <div className="copyright-right text-center ">
                <p className="copyright-text">
                  <>
                    Copyright © 2020-{new Date().getFullYear()}{" "}
                    <span>UPCX-PLATFORMS PTE. LTD.</span>
                  </>
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
